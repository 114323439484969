@keyframes blinking_02opc {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide_top {
  0% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide_right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(500%);
  }
}
