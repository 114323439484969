.flex-center {
  display: flex;
  align-items: center;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-start {
  display: flex;
  align-items: flex-start;
}
.flex-start-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f4;
  overflow: auto;
}

table {
  height: 1px;
}

body,
#App-header,
#App-before-header {
  min-width: 316px; /*(min-width: 320px , allocate 4px for scrollbar..)*/
}

#root {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  font-size: 12px !important;
  line-height: 1.2 !important;
  font-family: "Inter", sans-serif !important;
  font-feature-settings: "tnum" !important;
}

/* For popupsettings classname */
.popupsetting-w-200 {
  width: 200px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}
::-webkit-scrollbar {
  width: 9px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    rgba(0, 190, 242, 1) 30%,
    rgba(95, 177, 128, 1) 70%
  );
}

.custom-scrollbar::-webkit-scrollbar {
  width: 25px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* To override the KendoReact Css */

.mail-box {
  margin-top: 0px;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  padding: 15px;
}

.custom-toolbar {
  width: 100%;
  background-color: #f1f2f3;
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 1px;
  /* padding: 3px 8px; */
  color: #656565;
}

.custom-toolbar-scanner {
  width: 100%;
  background-color: #f1f2f3;
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 1px;
  /* padding: 3px 8px; */
  color: #656565;
  display:none;
}

.k-drawer-container {
  height: calc(100vh - 43px);
  
  /* width: 100vw; */
}

.k-drawer-container-scanner {
  height: calc(100vh - 43px);
  
  display : none !important;
  /* width: 100vw; */
}

.k-drawer-item {
  user-select: none;
  line-height: 18px !important;
  padding: 8px 1px;
  /* padding-top: 8px;
  padding-bottom: 8px; */
}

.k-drawer-item .k-item-code .k-icon {
  font-size: 14px;
  line-height: 1.7 !important;
  font-family: "Inter", sans-serif !important;
}

.k-drawer-item .k-item-code {
  font-size: 13px;
}

.k-drawer-item .k-item {
  font-size: 12px;
}

.k-item-text {
  padding-left: 20px !important;
  white-space: normal !important;
}

.k-drawer-item.sub-item {
  padding-left: 20px;
}

.k-drawer-item.k-state-selected {
  color: #000000 !important;
  background-color: #d6e3f5 !important;
}

.k-drawer-item:hover,
.k-drawer-item.k-state-hover {
  color: #000000 !important;
  background-color: #d6e3f5 !important;
}

.k-drawer-separator {
  background-color: rgba(0, 0, 0, 0.12);
}
.k-drawer-separator {
  padding: 0;
  height: 0px;
}

.k-editor {
  padding: 0px 15px;
}
.k-breadcrumb-link {
  color: #909090;
  font-family: "Inter", sans-serif !important;
}
.drawer-menu-button.k-icon-button.k-button-md {
  padding: 14px;
}
.k-list-container .k-popup .k-list-scroller .k-list .k-item,
.k-animation-container .k-popup .k-list-ul .k-list-item {
  font-size: 11px !important;
  font-family: "Inter", sans-serif !important;
  font-feature-settings: "tnum" !important;
  border-bottom: 1px solid #f0f3fa;
  padding: 4px !important;
}
.k-animation-container-shown,
.k-animation-container > .k-popup {
  box-shadow: 0px 8px 32px rgb(31 34 38 / 12%), 0px 2px 6px rgb(31 34 38 / 8%) !important;
  min-width: 80px;
}
.k-animation-container > .k-popup > .k-list-filter {
  padding: 4px;
}
.k-animation-container > .k-popup > .k-list-filter > .k-textbox,
.k-animation-container > .k-popup > .k-list-filter > .k-searchbox {
  border: none;
  border-bottom: 0.5px solid #757575;
  border-radius: 0;
  background-color: transparent;
  padding: 0px 0px 0px 2px; /*8px 0 8px 2px;*/
}
.k-animation-container
  > .k-popup
  > .k-list-filter
  > .k-searchbox
  > .k-i-search {
  width: 16px;
  height: 16px;
}
.k-animation-container
  > .k-popup
  > .k-list-filter
  > .k-searchbox
  > .k-input-inner,
.k-selected.k-list-optionlabel {
  padding: 4px;
  font-size: 14px;
}

.k-animation-container {
  z-index: 10003;
}
.k-dialog {
  max-height: 100%;
}
.k-dialog-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15 !important;
}
.k-grid-content {
  cursor: pointer;
  /* overflow: hidden; */
}
.k-input {
  border-width: 0px;
  background: none;
  font-size: 14px;
}
.k-input-value-text {
  font-size: 14px;
}
.k-input-button .k-button-icon {
  min-height: unset !important;
  color: rgba(0, 0, 0, 0.54);
  -webkit-appearance: none;
}
.k-input-button .k-button-icon:hover {
  color: #007bff;
  text-decoration: none;
}

.form-builder .k-grid th:first-child {
  padding-right: 0px;
}

.form-builder .k-grid td:first-child {
  padding-right: 0px;
  padding-left: 30px;
}

.form-builder .k-grid th:nth-child(2) {
  padding-left: 10px;
}

.form-builder .k-grid td:nth-child(2) {
  padding-left: 10px;
}

/* CSS Across Projects */
.CustomerBackground {
  background: url(asset/images/monthly_background/Customerimage.jpg) no-repeat
    center center / cover;
}

.Background01 {
  background: url(asset/images/monthly_background/01_BackgroundJanuary-min.jpg)
    no-repeat center center / cover;
}

.Background02 {
  background: url(asset/images/monthly_background/02_BackgroundFebruary-min.jpg)
    no-repeat center center / cover;
}

.Background03 {
  background: url(asset/images/monthly_background/03_BackgroundMarch-min.jpg)
    no-repeat center center / cover;
}

.Background04 {
  background: url(asset/images/monthly_background/07_BackgroundJuly-min.jpg)
    no-repeat center center / cover;
}

.Background05 {
  background: url(asset/images/monthly_background/07_BackgroundJuly-min.jpg)
    no-repeat center center / cover;
}

.Background06 {
  background: url(asset/images/monthly_background/06_BackgroundJune-min.jpg)
    no-repeat center center / cover;
}

.Background07 {
  background: url(asset/images/monthly_background/07_BackgroundJuly-min.jpg)
    no-repeat center center / cover;
}

.Background08 {
  background: url(asset/images/monthly_background/08_BackgroundAugust.jpg)
    no-repeat center center / cover;
}

.Background09 {
  background: url(asset/images/monthly_background/09_BackgroundSeptember-min.jpg)
    no-repeat center center / cover;
}

.Background10 {
  background: url(asset/images/monthly_background/10_BackgroundOctober.jpg)
    no-repeat center center / cover;
}
.Background11 {
  background: url(asset/images/monthly_background/11_BackgroundNovember-min.jpg)
    no-repeat center center / cover;
}
.Background12 {
  background: url(asset/images/monthly_background/12_BackgroundDecember.jpg)
    no-repeat center center / cover;
}

.k-grid th {
  /* border-left-color: transparent;
  font-size: 10px;
  padding: 12px 5px;
  border-style: solid;
  outline: 0; */
  font-weight: bold;
  /* text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; */
}

/* .k-grid td {
  border-left-color: transparent;
  font-size: 10px;
  padding: 5px;
  border-style: solid;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}  */

.gridHeader {
  margin: -16px -24px;
  padding: 5px;
  font-weight: bold;
  line-height: inherit;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 0;
  text-align: center;
}

.gridinput {
  margin: 0;
  padding: 0;
  height: calc(1.125em + 18px);
  outline: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  box-sizing: border-box;
  box-shadow: none;
  background: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
}

.alignCenterGrid {
  text-align: center !important;
}

.alignLeftGrid {
  text-align: left !important;
}

.alignRightGrid {
  text-align: right !important;
}

.productCell {
  padding: 5px 10px !important;
  text-align: center !important;
  border-left-color: transparent;
  font-size: 10px;
  border-style: solid;
  outline: 0;
  font-weight: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GridBox {
  padding: 14px 15px;
}

/* disable password reveal in EDGE browser*/
::-ms-reveal {
  display: none;
}

.react-form-builder-toolbar ul li:last-of-type {
  display: none;
}
.button {
  background-color: #245396 !important; /*00827e*/
  border-color: #245396 !important; /*245396*/
  color: #ffffff !important;
}
.button.disabled, 
.button:disabled {
  background-color: #a9a9a9 !important; /* Grey background */
  border-color: #a9a9a9 !important;     /* Grey border */
  color: #d3d3d3 !important;            /* Light grey text */
  cursor: not-allowed !important;       /* Show not-allowed cursor */
  opacity: 0.6;                         /* Slightly faded */
}
.autoComplete {
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px 0px 1px 0px !important;
}
.select{
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px 0px 1px 0px !important;
  font-size: "12px";
}
.dateRangeCalender {
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px 1px 1px 1px !important;
}

.dateRangeCalender .k-input-md .k-input-inner {
  padding: 0px 16px !important;
}

.dateRangeCalender .k-daterange-picker .k-dateinput,
.k-daterangepicker .k-dateinput {
  width: 9em !important;
  padding-left: 19px !important;
  padding-top: 0px !important;
}

.dateRangeCalender .k-floating-label-container {
  border-width: 0px 1px 1px 1px !important;
  padding: 0px !important;
  padding-top: 4px !important;
}
.k-daterangepicker-wrap {
  padding: 19px !important;
  padding-top: 5px !important;
}

.dateCalender {
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px px 1px 0px !important;
  width: 138px !important;
}

.input {
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px 0px 1px 0px !important;
}

.k-dropdown-button {
  background: transparent;
}
.k-dropdown-button button {
  background-color: #245396 !important; /*00827e*/
  border-color: #245396; /*245396*/
  color: white;
  border: none;
  text-transform: uppercase;
  /* font-weight: bold; */
}
.k-dropdown-button buttonDisabled {
  background-color: #ffffff; /*00827e*/
  border-color: #245396; /*245396*/
  color: rgb(0, 0, 0);
  border: none;
  text-transform: uppercase;
  /* font-weight: bold; */
}

.k-grid .table-style th {
  padding: 5px 10px;
}
.table-style td {
  padding: 5px 10px;
}

.k-toolbar .action .k-button-solid-base {
  background: #245396;
}

.k-drawer-start {
  height: calc(100% - 140px) !important;
}

.k-drawer-wrapper::-webkit-scrollbar {
  width: 10px;
}

.checkbox {
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px 0px 1px 0px !important;
}

.k-grouping-row td:nth-child(2) {
  display: none;
}

.k-grouping-row td:nth-child(3) {
  display: none;
}
.k-grouping-row td:nth-child(4) {
  display: none;
}

.menu-scroll::-webkit-scrollbar {
  /* display: none; */
  width: fit-content;
  height: 2px;
}
.menu-scroll .k-bottom-nav-item {
  border-right: 1px solid #e9ebeb;
}
.menu-scroll .selected {
  background: linear-gradient(
    90deg,
    rgba(0, 190, 242, 1) 30%,
    rgba(95, 177, 128, 1) 70%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  color: rgba(0, 190, 242, 1);
}

/* .colgroup{
  width:100% !important;
} */

/* .k-grid th:first-child, .k-grid td:first-child{
  padding:10px
} */

.k-grid .k-detail-row .k-detail-cell {
  padding: 0.5px !important;
}
.k-cell-inner > .k-link {
  padding: 18px 20px !important;
}
.k-grid th,
.k-grid td {
  padding: 7px !important;
}
.k-loading-image::before,
.k-loading-image::after {
  display: none;
}
.k-grid {
  font-size: 12px !important;
}
.k-column-title {
  font-size: 11px !important;
  white-space: normal !important;
}
/* .k-i-loading::after, .k-loading-image::after{
  display:none;
} */
.setupTab .k-content .k-animation-container {
  min-width: 100% !important;
}

.grid .k-grid-content .k-alt {
  background-color: whitesmoke !important;
}
.k-grid .k-grouping-row .k-icon{

    margin-left: 0px;

}

.k-grid .k-grouping-row p{

  color:rgb(0, 133, 224)
}
.mobile-grid .k-grid-header-wrap{
  /* display: none;  */
}

/* .mobile-grid .k-grid-content{
  overflow: auto !important;
  /* max-width: 330px !important; *
  overflow-y: auto !important;
}  */

/* .mobile-grid .k-grid-header .k-header:first-child{
  display: none;
} */

/* .mobile-grid .k-grid-header .k-header {
  display: none ;
}

.mobile-grid .k-grid-header .k-header:last-child{
  display:inline !important;
} */

/* .mobile-grid .k-grid-content{
  cursor: pointer;
  width: 100%;
    min-height: 0 !important;
    overflow: auto !important;
    overflow-x: auto !important;
    overflow-y: scroll !important;
    position: relative !important;
}*/

/* .mobile-grid .k-grid-content .k-virtual-content{
  /* width: 100%; */
    /* min-height: 0; */
    /* max-width: fit-content; *
    overflow: auto;
    overflow-x: auto;
    overflow-y: scroll;
    position: relative;
} */

/* .mobile-grid .k-pager-wrap {
  overflow: auto !important;
}
/* 
.mobile-grid  {
  height: 400px;
  max-width: 310px;
  cursor: pointer;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
} */

/* .mobile-grid  {
  height: 400px;
  /* max-width: 800px; *
  overflow-y: scroll;
 }  */
 
.cancelButton{
  background-color: whitesmoke !important; /*00827e*/
  border-color: whitesmoke !important; /*245396*/
  color: black;
}

.drawer-shrink:hover {
  background: #DDDDDD;
}

.mobile-grid .k-grid-header-wrap{
  /* display: none;  */
}

.k-drawer-content {
  overflow: auto;
}

.k-drawer-content-scanner {
  overflow: auto;
  height: 100% !important;
  margin-left:0px !important;
}

.k-grid-edit-cell input {
  font-size: 12px;
  border: solid 1px #cdd3df;
  border-radius: 5px;
  height: 25px;
  padding: 8px 8px;
}

.inlineEdit .k-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: whitesmoke;
}
.licenseSetupDatePicker{
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke !important;
  height: 37px !important;
  border-width: 0px px 1px 0px !important;
  width: 260px !important;
  font-size:12px !important;
}

.inlineEdit .k-cell-inner > .k-link {
  padding: 18px 23px !important;
}

.editField input {
  border: 1px solid rgb(205, 211, 223) !important;
  border-radius: 5px !important;
  height: 25px !important;
  padding: 8px 8px;
}

.editField .k-dropdownlist {
  border: 1px solid rgb(205, 211, 223) !important;
  border-radius: 5px !important;
  height: 25px !important;
}

.editField .k-input {
  border-radius: 5px !important;
  height: 25px !important;
  background-color: whitesmoke;
}

.editField .k-textarea {
  height: unset !important;
  border: 1px solid rgb(205, 211, 223) !important;
  border-radius: 5px !important;
  padding: 5px 2px !important;
}

.k-textarea .k-input-inner {
  overflow: auto;
}

.editField .k-link, .editField .k-state-focus{
  height: 30px;
  background: whitesmoke !important;
  color: black !important;
}
.editField ul {
  border-radius: 10px;
}

.editField .k-animation-container {
  z-index: 100;
}
.editField .k-input-inner {
  padding: 0px 3px;
  overflow: auto;
  resize: none;
}
.k-grid .inEdit td{
  vertical-align: top !important;
}

.editField .k-button-group {
  height: 25px;
  border-radius: 5px;
}

.editField .k-checkbox {
  height: 1px !important;
  border-radius: 3px !important;
}

.k-detail-row {
  background: whitesmoke !important;
}

.inner-grid .k-grid-content{
  overflow: hidden;
}

.driverECRDetailTable
.k-grid-content table, .k-grid-content-locked table{
  /* display:table !important; */
  width:inherit !important;
}

.autoCompleteCross .k-clear-value {
  margin-left: -8px;
  margin-right: -5px;
}

.numericPadding .k-input-inner {
  padding: 0px 4px;
}

.banner div{
  margin: 0 !important;
  width: 100%;
  
}

.mobile-grid .k-grid-header-wrap{
  display: none; 
}

.my-panelBar .k-item > .k-animation-container {
  z-index: 10;
}

.verticalAlignTop {
  vertical-align: top !important;
}

.my-panelBar > .k-item > .k-link.k-state-selected:focus,
.my-panelBar > .k-item > .k-link.k-state-selected.k-state-focus,
.my-panelBar > .k-item > .k-link.k-state-selected.k-state-focused,
.my-panelBar > .k-panelbar-header > .k-link.k-state-selected:focus,
.my-panelBar > .k-panelbar-header > .k-link.k-state-selected.k-state-focus,
.my-panelBar > .k-panelbar-header > .k-link.k-state-selected.k-state-focused {
  background-color: #245396  !important;
}

.my-panelBar > .k-item > .k-link.k-state-selected:hover,
.my-panelBar > .k-item > .k-link.k-state-selected.k-state-hover,
.my-panelBar > .k-panelbar-header > .k-link.k-state-selected:hover,
.my-panelBar > .k-panelbar-header > .k-link.k-state-selected.k-state-hover {
  background-color: #245396  !important;
}


.my-panelBar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected
{
color:white!important;
background-color:#245396 !important;
}



.my-panelBar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link
{
color:#245396  !important;
background-color:white!important;
}

.k-i-hyperlink-open:hover {
  color: #007bff;
  cursor: pointer;
}

.circle {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  font-size: 18px;
  background: linear-gradient(90deg,rgba(0, 190, 242, 1) 30%,rgba(95, 177, 128, 1) 70%);
  text-align: center;
  color: white;
}

.hover-content-beta {
  display: none;
  cursor: pointer;
  font-size: 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  position: absolute;
  margin-top: -35px;
}

.hover-show:hover .hover-content-beta {
  display: block;
}


.floatingActionButtonImportant {
  z-index: 50003 !important;
  overflow:visible !important;
  position: fixed !important;
  right:  255px !important;
  bottom: 240px  !important;
}


.floatingActionButtonImportant2 {
  z-index: 50003 !important;
  overflow:visible !important;
  position: fixed !important;
  right:  255px !important;
  bottom: 240px  !important;
}

.floatingActionButtonImportant3 {
  z-index: 50003 !important;
  overflow:visible !important;
  position: fixed !important;
  right:  57%!important;
  bottom:  58%!important;
}

.floatingActionButtonImportant4 {
  z-index: 50003 !important;
  overflow:visible !important;
  position: fixed !important;
  right:  57%!important;
  bottom:  62%!important;
}

.floatingActionButtonImportant5 {
  z-index: 50003 !important;
  overflow:visible !important;
  position: fixed !important;
  right:  58%!important;
  bottom:  70%!important;
}

.floatingActionButtonImportant6 {
  z-index: 50003 !important;
  overflow:visible !important;
  position: fixed !important;
  right:  96%!important;
  bottom:  90%!important;
}

.floatingActionButtonImportant7 {
  z-index: 50003 !important;
  overflow:visible !important;
  position: absolute !important;
  right:  90%!important;
  bottom:  80%!important;
}


.no-header-grid .k-grid-header {
display: none !important;
}

.fab-wrapper .k-switch-md {
  left: 210px !important;
}


/* .header .k-switch-md {
  left: 145px !important;
}  */


.location .k-switch-md {
  left: 190px !important;
} 


.onsite .k-switch-md {
  left: 140px !important;
} 

.k-animation-container-relative{
  width: auto;
}

.notification-panel {
  background-color: #f5f8fd;
  border-color: #8bb4e7 !important;
  color: #4F5256;
  border-left: 5px solid #eee;
  padding: 5px 10px 3px 10px;
}

.remove-padding-mobile .k-content {
  padding: 0px;
}

.mobile-small-button {
  font-size: 10px;
  min-height: 28px;
  min-width: 50px;
}

.padding8px .k-input-inner {
  padding: 8px !important;
}

.k-state-disabled .k-checkbox-label {
  color: black !important;
}

.react-pdf__Document {
  overflow: auto;
}
.react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Page__annotations {
  display: none;
}
@media (max-width: 620px) {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
  .report-dialog .k-window-content {
    width: 95vw !important;
    height: 85vh !important;
  }
}
.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.subheading ~td .k-input {
  display: none;
}
.subheading ~td .k-dropdownlist {
  display: none;
}